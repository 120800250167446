import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import React, { useEffect } from "react";
import { Button } from "../../ui/button";
import { DotButton, useDotButton } from "./DotButton";
import Link from "next/link";

type PropType = {
  slides: string[];
  options?: EmblaOptionsType;
  htmlContent?: string;
};

const NewImageCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props;
  const [carouselSlides, setCarouselSlides] = React.useState<string[]>(
    slides ?? []
  );
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  useEffect(() => {
    setCarouselSlides(slides);
  }, [slides]);

  useEffect(() => {
    if (!emblaApi) return;

    const intervalId = setInterval(() => {
      const nextIndex = (selectedIndex + 1) % carouselSlides.length;
      emblaApi.scrollTo(nextIndex);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [emblaApi, selectedIndex, carouselSlides.length]);

  return (
    <div className="embla_2 relative flex justify-center">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {carouselSlides.map((slide, index) => (
            <div className="embla__slide_2" key={index}>
              <div className="embla__slide__images">
                <img
                  src={slide}
                  alt="Carpets"
                  className="w-screen h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-[#00000000]  to-[rgba(0,0,0,0.6)] pointer-events-none z-0" />

      <div className="absolute bottom-[120px] sm:bottom-[212px] z-1 flex flex-col space-y-[30px]">
        <img src="assets/bannerText.svg" alt="" className="w-[270x] h-[70px]" />
        <div className="flex flex-col sm:flex-row space-y-[15px] sm:space-x-[15px] sm:space-y-0 md:space-x-[30px] justify-between items-center">
          <Button
            asChild
            variant={"default"}
            className="border-none rounded-none text-white opacity-1 bg-primary cursor-pointer p-0 w-full"
          >
            <div>
              <Link passHref href={"/carpets"}>
                <a className="!text-white px-[32.5px] py-[10.5px]">
                  コレクションを見る
                </a>
              </Link>
            </div>
          </Button>
          <Button
            asChild
            variant={"default"}
            className="border-none rounded-none text-white opacity-1 cursor-pointer px-[6px] py-[10.5px] bg-[#333333] w-full"
          >
            <a
              href="https://timerex.net/s/support_d67a_0fb3/8f8d5baa"
              target="_blank"
              className="!text-white"
            >
              ショールームを予約する
            </a>
          </Button>
        </div>
      </div>

      <div className="absolute bottom-[52px] z-1">
        <div className="flex justify-center flex-wrap">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={`w-2 h-2 m-1 rounded-full p-2 bg-[#FFFFFF] border-none opacity-50 cursor-pointer ${index === selectedIndex ? "!bg-[#009688] opacity-1" : ""}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewImageCarousel;
