import { icons } from "antd/lib/image/PreviewGroup";
import React from "react";

interface RatingCardProps {
  message: string;
  name: string;
  icon?: string;
  iconWidth?: number;
  iconHeight?: number;
}

const RatingCard = ({
  message,
  name,
  icon,
  iconHeight,
  iconWidth,
}: RatingCardProps) => {
  return (
    <div className="min-w-[410px] max-w-[410px] p-[30px] bg-[#FFFFFF] rounded-[8px] border border-[#EDEDED] min-h-full">
      <div className="flex flex-col space-y-5 h-full">
        <img src={"assets/starRating.svg"} alt="" height={24} width={128} />
        <div className="flex flex-col space-y-[15px] justify-between h-full">
          <p className="mb-0 text-[16px] font-medium leading-[24px] text-[#333333] break-words whitespace-normal">
            {message}
          </p>
          <div className="flex flex-col justify-start space-y-2">
            <p className="mb-0 text-[#1B2E32] font-medium text-[13px]">
              {name}
            </p>
            {icon && (
              <img src={icon} alt="" height={iconHeight} width={iconWidth} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingCard;
