import dynamic from "next/dynamic";
import React from "react";
import { useScroll } from "../../../hooks/use-scroll";
import { cn } from "../../../lib/utils";
import { ContactCall } from "../ContactCall";
import { ContactEmail } from "../ContactEmail";

const LineButton = dynamic(() => import("../LineButton"), {
  ssr: false,
});

const StatusBar: React.FC = () => {
  const isScrolled = useScroll();
  return (
    <section
      className={cn(
        "flex items-center justify-between bg-primaryDark text-white md:px-2 lg:px-24 xl:px-[260.5px] transition-all duration-300 ease-in-out",
        isScrolled ? "md:py-0" : "md:py-[13.5px]"
      )}
    >
      <p className="m-0 text-sm font-medium hidden md:block py-[13.5px]">
        高級ペルシャ絨毯専門店 日本国内から発送 品質・返品保証
      </p>
      <div className="flex items-stretch space-x-1 xs:space-x-3 md:space-x-5 ml-auto">
        <div className="py-[13.5px] flex items-center space-x-1 xs:space-x-3 md:space-x-5 pl-1 xs:pl-0">
          <LineButton />
          <ContactEmail />
        </div>
        <ContactCall phoneNumber={"049-293-4004"} />
      </div>
    </section>
  );
};

export { StatusBar };
