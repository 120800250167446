import React from "react";
import NewsCard from "../NewsCard";

const ServiceData = [
  {
    title: "ペルシャ絨毯の鑑定",
    description:
      "現地の専門知識を持つ鑑定士や作品の工房に直接査定を依頼し、その結果をお客様にお伝えいたします。鑑定書の発行も可能で、大切な一品を正しく評価いたします。",
    image: "assets/service/service2.png",
    url: "",
  },
  {
    title: "ペルシャ絨毯のリクエスト",
    description:
      "お客様のご希望の条件をぜひお聞かせください。ご要望に合ったペルシャ絨毯を現地の工房から厳選してお探しし、ご提案させていただきます。また、まとめて大量にご注文いただく場合も、問い合わせフォームからお気軽にご連絡ください。",
    image: "assets/service/service4.png",
    url: "request",
  },
  {
    title: "ペルシャ絨毯のクリーニング・修理",
    description:
      "熟練の職人が現地の工房で丁寧にクリーニングを行い、ペルシャ絨毯本来の美しさを取り戻します。長年の汚れやシミも安心してお任せください。",
    image: "assets/service/service3.png",
    url: "",
  },
];

const ServiceSection = () => {
  return (
    <div className="w-auto bg-white  pt-[30px] sm:pt-[60px] md:pt-[88px] pb-[30px] px-2">
      <div className="max-w-[1400px] w-full mx-auto flex flex-col space-y-[30px] md:space-y-[60px] px-2">
        <div className="flex flex-col space-y-4 max-w-[1174px] mx-auto">
          <h2 className=" text-[16px] md:text-[40px] leading-6 md:leading-[60px] font-black text-primary text-center mb-0">
            サービス
          </h2>
          <p className="text-[24px] md:text-[38px] leading-7 md:leading-[45px]  font-black  text-center mb-0">
            大切なペルシャ絨毯を末永く美しく保ち、
          </p>
          <p className="text-[24px] md:text-[38px] leading-7 md:leading-[45px] font-black  text-center mb-0">
            最大限の価値を引き出すための多彩なサービスをご提供しています。
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
          {ServiceData.map((data, index) => (
            <NewsCard
              key={index}
              title={data.title}
              description={data.description}
              image={data.image}
              url={data.url}
              hideReadMore
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
