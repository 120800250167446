import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { pickRandomItems } from "shared";
import { cn } from "../../../lib/utils";
import { getLandingGifs } from "../../../services/carpets";

const ImagesSlideShow: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref to manage interval

  const { data: imagesList } = useQuery<string[]>({
    queryKey: ["landing-gifs"],
    queryFn: async () => getLandingGifs().then((res) => res.data),
    select: (data) => pickRandomItems(data, 20),
  });

  const startSlideshow = () => {
    if (intervalRef.current || !imagesList || imagesList.length < 2) return;
    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % imagesList.length);
    }, 600);
  };

  const stopSlideshow = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    startSlideshow();
    return () => stopSlideshow();
  }, [imagesList]);

  const handleMouseEnter = () => {
    stopSlideshow(); // Immediately stop the slideshow on hover
  };

  const handleMouseLeave = () => {
    startSlideshow(); // Resume the slideshow on mouse leave
  };

  return (
    <div
      className="relative sm:min-w-[393px] w-full h-[599px] overflow-hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      {imagesList &&
        imagesList.length > 0 &&
        imagesList.map((image, index) => (
          <img
            alt=""
            src={image}
            key={`slideshow__${index}`}
            draggable={false}
            className={cn(
              "inset-0 absolute size-full object-cover duration-500 transition-all ease-linear",
              currentImageIndex === index ? "fade-in-50" : "fade-out-50"
            )}
            loading="lazy"
          />
        ))}
    </div>
  );
};

export default ImagesSlideShow;
