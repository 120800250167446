import React from "react";
import { Button } from "../../ui/button";
import Link from "next/link";

const LandingContactForm = () => {
  return (
    <div className="w-auto bg-[#19292C] relative flex justify-center items-center ">
      <img
        src="assets/contactBackground.png"
        alt="Banner Image"
        className="w-full h-full min-h-[750px] sm:min-h-[534px] max-h-[534px] min-w-full object-cover"
      />
      <div className="absolute max-w-full md:max-w-[925px] w-full top-[80px] sm:top-[70px] self-center p-[13px] flex justify-center flex-col items-center space-y-[18px]">
        <div className="md:max-w-[690px] w-full flex flex-col space-y-4 justify-center items-center p-2 text-center">
          <h2 className="mb-0 font-black text-[24px] md:text-[38px] leading-[45px] text-[#FFFFFF]">
            お問い合わせフォームもしくはお電話にてお受けしています
          </h2>
          <p className="mb-0 text-[#FFFFFF] text-[14px] leading-[16px] font-light text-center">
            在庫確認や価格交渉、取り寄せのご要望など、お気軽にお問い合わせください。
          </p>
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-between md:max-w-[644px] w-full space-y-4 sm:space-y-0">
          <div className="py-0 sm:py-[24.5px] flex flex-col justify-center items-center space-y-[15px] max-w-[265px] w-full">
            <img src="assets/landingMail.svg" alt="LandingMailIcon" />
            <p className="mb-0 text-[#FFFFFF] text-[16px] sm:text-[24px] leading-[27px] font-bold text-center">
              メールでのお問い合わせ
            </p>
            <div className="flex justify-center items-center">
              <Button
                variant={"default"}
                asChild
                className="border-none text-[14px] sm:text-[16px] leading-[27px] rounded-none text-white opacity-1 bg-primary cursor-pointer p-0 font-bold"
              >
                <div>
                  <Link passHref href={"/contact"}>
                    <a className="!text-white px-[52px] py-[10.5px]">
                      お問い合わせフォーム
                    </a>
                  </Link>
                </div>
              </Button>
            </div>
          </div>

          <div className="py-0 sm:py-[24.5px] flex flex-col justify-center items-center space-y-[15px] max-w-[240px] w-full ">
            <img src="assets/landingPhone.svg" alt="LandingPhoneIcon" />
            <p className="mb-0 text-[#FFFFFF] text-[16px] sm:text-[24px] leading-[27px] font-bold">
              お電話のお問い合わせ
            </p>
            <div className="flex flex-col justify-center items-center space-y-2">
              <a
                href="tel:080-3578-9185"
                className="mb-0 !text-[#FFFFFF] text-[16px] sm:text-[24px] leading-[27px] cursor-pointer font-bold"
              >
                080-3578-9185
              </a>
              <a
                href="tel:049-293-4004"
                className="mb-0 !text-[#FFFFFF] text-[16px] sm:text-[24px] leading-[27px] cursor-pointer font-bold"
              >
                049-293-4004
              </a>
              <p className="mb-0 text-[#FFFFFF] text-[10px] sm:text-[13px] leading-[27px] font-bold text-center">
                営業時間 AM10:00～PM5:00
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingContactForm;
