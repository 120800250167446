import React from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { cn } from "../../../lib/utils";

export const HEADERLINKS = [
  {
    title: "about golestan",
    link: "/about-us",
  },
  {
    title: "Customer Voice",
    link: "/customer-voice",
  },
  {
    title: "Workshop Introduction",
    link: "/mirmehdi",
  },
  {
    title: "carpet articles",
    link: "/articles",
  },
  {
    title: "Shopping Guide",
    link: "/guides",
  },
  {
    title: "faq",
    link: "/articles/persian-carpet-faq",
  },
];

const HeaderMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  return (
    <>
      {HEADERLINKS.map((item, index) => (
        <Link key={`header-link-${index}`} href={item.link} passHref>
          <a
            className={cn(
              "cursor-pointer font-medium leading-6 tracking-[0.3px] !text-black hover:!text-primary hover:font-medium lg:whitespace-nowrap",
              pathname.includes(item.link) && "!text-primary font-medium"
            )}
          >
            {t(item.title)}
          </a>
        </Link>
      ))}
    </>
  );
};

export { HeaderMenu };
