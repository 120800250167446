import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import { useScroll } from "../../../hooks/use-scroll";
import { cn } from "../../../lib/utils";
import { AuthContext } from "../../../pages/_app";
import { CartMenu } from "../../molecules/CartMenu";
import { HeaderMenu } from "../../molecules/HeaderMenu";
import { HeaderModal } from "../../molecules/HeaderModal";
import { Login } from "../../molecules/LogIn";
import { StatusBar } from "../../molecules/StatusBar";

interface Props {
  cartCounter?: number;
}

const UserMenu = dynamic(
  () =>
    import("../../../components/molecules/User").then((mod) => mod.UserMenu),
  {
    ssr: false,
    loading: () => <div className="h-8 w-24 animate-pulse rounded" />,
  }
);

const Header: React.FC<Props> = ({ cartCounter }: Props) => {
  const { authenticated = false, loading = true } = useContext(AuthContext);
  const [userData, setUserData] = useState(false);
  useEffect(() => {
    setUserData(authenticated);
  }, [authenticated, loading]);
  const isScrolled = useScroll();
  return (
    <header className="max-w-[1920px] w-full mx-auto sticky top-0 z-[40] bg-white !font-dmSans shadow-sm sm:shadow-md">
      <StatusBar />
      <div
        className={cn(
          "transition-all duration-500 ease-linear",
          isScrolled ? "md:py-2" : "py-[5px] md:py-3 "
        )}
      >
        <section
          className={cn(
            "flex items-center flex-wrap justify-between space-x-4 xs:space-x-0 3xl:gap-0 px-2 xs:px-4 2xl:px-[260.5px] py-1 transition-all duration-300 ease-in-out",
            isScrolled ? "md:py-2 md:gap-2" : "md:py-[26.5px] md:gap-[26.5px]"
          )}
        >
          <div className="flex items-center space-x-3 sm:space-x-[15px] order-1">
            <HeaderModal />
            <Link href={"/"} passHref>
              <a className="h-[28px] w-[108px] md:h-[40px] md:w-[156px]">
                <Image
                  src="/assets/golestan-logo-new.svg"
                  alt="Golestan"
                  height={40}
                  width={156}
                  priority
                  quality={100}
                />
              </a>
            </Link>
          </div>
          <section className="items-center justify-center space-x-4 lg:space-x-[30px] hidden md:flex order-3 3xl:order-2 grow text-xs lg:text-sm w-full 2xl:w-fit">
            <HeaderMenu />
          </section>
          <div className="flex items-center space-x-[15px] md:space-x-[30px] order-2 3xl:order-3 ml-auto">
            {loading && <div className="h-8 w-24 animate-pulse rounded" />}
            {userData ? <UserMenu /> : <Login />}
            <CartMenu counter={cartCounter} />
          </div>
        </section>
      </div>
    </header>
  );
};

export { Header };
