import { useQuery } from "@tanstack/react-query";
import React from "react";
import { IArticlesProps } from "../../../interfaces/CarpetData";
import { getFilteredArticles } from "../../../services/articles";
import { Button } from "../../ui/button";
import NewsCard from "../NewsCard";
import Link from "next/link";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel";

const NewArticleSection: React.FC = () => {
  const { data: articles } = useQuery<IArticlesProps[]>({
    queryKey: ["filtered-articles"],
    queryFn: async () => getFilteredArticles().then((res) => res.data),
  });
  return (
    <div className="w-auto bg-[#F5F5F5] pt-[30px] sm:pt-[60px] md:pt-[88px] pb-[30px]">
      <div className="max-w-[1406px] w-full mx-auto flex flex-col space-y-[30px] md:space-y-[60px] px-4">
        <div className="flex flex-col space-y-4 max-w-[1174px] mx-auto">
          <h2 className=" text-[16px] md:text-[40px] leading-6 md:leading-[60px] font-black text-primary text-center mb-0">
            記事
          </h2>
          <p className="text-[24px] md:text-[38px] leading-7 md:leading-[45px]  font-black  text-center mb-0">
            ペルシャ絨毯に詳しくなる
          </p>
        </div>
        {articles && articles.length > 0 && (
          <Carousel opts={{ loop: true, align: "center" }}>
            <CarouselContent>
              {articles.map((article) => (
                <CarouselItem
                  className="sm:basis-1/2 md:basis-1/3"
                  key={article.id}
                >
                  <NewsCard
                    key={article.id}
                    title={article.title}
                    description={article.summary}
                    image={article.image}
                    url={`articles/${article.slug}`}
                    backgroundColor
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="bg-black [&>*]:!size-5 left-2 2xl:-left-4" />
            <CarouselNext className="bg-black [&>svg]:!size-5 right-2 2xl:-right-4" />
          </Carousel>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px]"></div>
        <div className="flex justify-center items-center">
          <Button
            variant={"default"}
            className="border-none w-full sm:w-auto text-[16px] md:text-[20px] leading-[27px] rounded-none text-white opacity-1 bg-primary cursor-pointer p-0"
          >
            <div className="w-full">
              <Link passHref href={"/articles"}>
                <a className="!text-white w-full px-[64.5px] py-[10.5px] md:py-[15.5px]">
                  すべての記事を見る
                </a>
              </Link>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewArticleSection;
