import { ChevronRightIcon, MenuIcon } from "lucide-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "../../../components/ui/sheet";
import { cn } from "../../../lib/utils";
import { HEADERLINKS } from "../HeaderMenu";

export const HeaderModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { pathname } = useRouter();
  const { t } = useTranslation();
  return (
    <Sheet open={showModal} onOpenChange={setShowModal}>
      <SheetTrigger asChild>
        <button>
          <MenuIcon className="text-primary size-6 xs:size-[38px] md:hidden" />
          <span className="sr-only">view header menu</span>
        </button>
      </SheetTrigger>
      <SheetContent side="left" className="bg-white px-0">
        <SheetTitle className="px-4 -mt-4 text-sm font-medium leading-[30px] tracking-[0.3px]">
          {t("Menu")}
        </SheetTitle>
        <ul className="flex flex-col list-none">
          {HEADERLINKS.map((item, index) => (
            <Link
              href={item.link}
              passHref
              key={`mobile_header_links__${index}`}
            >
              <a className="!text-body" onClick={() => setShowModal(false)}>
                <li
                  className={cn(
                    "px-4 py-1 h-[38px] flex items-center justify-between hover:bg-primary hover:text-white transition-colors duration-200 ease-out",
                    pathname.includes(item.link) && "bg-primary text-white"
                  )}
                >
                  <span className="font-medium text-sm">{t(item.title)}</span>
                  <ChevronRightIcon className="size-4" />
                </li>
              </a>
            </Link>
          ))}
        </ul>
      </SheetContent>
    </Sheet>
  );
};
