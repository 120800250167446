import Link from "next/link";
import { cn } from "../../../lib/utils";
import React from "react";
import { truncateText } from "shared";
import { useTranslation } from "next-i18next";

interface ITestimonialsCardProps {
  className?: string;
  image: string;
  review: string;
  isEven?: boolean;
  id: string;
}

const TestimonialsCard = ({
  className,
  review,
  image,
  isEven,
  id,
}: ITestimonialsCardProps) => {
  const { t } = useTranslation();
  return (
    <Link href={`/customer-voice?id=${id}`} passHref>
      <a className="hover:drop-shadow-md transition-all group duration-300">
        <div
          className={cn(
            "flex items-center flex-wrap justify-center lg:flex-nowrap",
            isEven ? "flex-row-reverse" : "flex-row",
            className
          )}
        >
          <img
            src={image}
            alt={`carpet_${review}`}
            height={290}
            width={515}
            className="h-[290px] max-w-[515px] w-full object-cover flex shrink-0"
          />
          <div
            className={cn(
              "flex justify-start p-[30px] max-w-[496px] items-start w-full space-x-[15px] bg-[#F2F2F2] bg-opacity-90 rounded-[10px] -mt-4 lg:mt-0 z-10",
              isEven ? "lg:-mr-[60px]" : "lg:-ml-[60px]"
            )}
          >
            <img
              src={"/assets/quotePrimary.svg"}
              alt=""
              height={34}
              width={38}
              className="object-contain"
            />
            <p className="mb-0 text-[#1B2E32] whitespace-pre-wrap break-all text-base">
              {review.length <= 215 ? (
                review
              ) : (
                <>
                  {truncateText(review, 214)}
                  <span className="!text-primary inline-flex group-hover:underline">
                    {t("Read more")}
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default TestimonialsCard;
