import Link from "next/link";
import React, { ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { truncateText } from "shared";
import { cn } from "../../../lib/utils";

interface INewsCardProps {
  title: string;
  description: string;
  image?: string;
  url?: string;
  id?: string;
  backgroundColor?: boolean;
  hideReadMore?: boolean;
}

interface IDynamicWrapperProps {
  url?: string;
  children: ReactNode;
  className?: string;
}

const DynamicWrapper = ({
  url,
  children,
  className,
}: IDynamicWrapperProps): JSX.Element => {
  if (url) {
    return (
      <Link href={url}>
        <a className={className}>{children}</a>
      </Link>
    );
  }
  return <div className={className}>{children}</div>;
};

const NewsCard = ({
  title,
  description,
  image,
  url,
  backgroundColor = false,
  hideReadMore = false,
}: INewsCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="md:max-w-[687px] h-full w-full">
      <DynamicWrapper url={url} className="!h-full !flex !text-headerText">
        <div
          className={cn(
            "flex flex-col w-full justify-center overflow-hidden transition-shadow duration-300",
            url && "group hover:shadow-md"
          )}
        >
          {image ? (
            <img
              src={image}
              alt={`${title} image`}
              className="w-full min-h-[300px] max-h-[300px] object-cover group-hover:scale-105 duration-500 transition-transform"
            />
          ) : (
            <div className="w-full min-h-[300px] max-h-[300px] bg-[#F5F5F5]" />
          )}
          <div
            className={cn(
              "p-[15px] sm:p-[30px] flex flex-col space-y-[15px] h-full justify-start",
              backgroundColor ? "bg-white" : "bg-[#F5F5F5]"
            )}
          >
            <p
              className={cn(
                "text-[20px] md:text-[28px] leading-9 text-left mb-0 !font-bold transition-colors duration-300 break-all line-clamp-1 !text-headerText !m-0",
                url && "group-hover:!text-primary"
              )}
            >
              {title}
            </p>
            <span className="text-base font-normal text-start !text-body break-all">
              {hideReadMore ? (
                description
              ) : (
                <>
                  {description.length <= 75 ? (
                    description
                  ) : (
                    <>
                      {truncateText(description, 74)}
                      <span className="!text-primary inline-flex hover:underline">
                        {t("Read more")}
                      </span>
                    </>
                  )}
                </>
              )}
            </span>
          </div>
        </div>
      </DynamicWrapper>
    </div>
  );
};

export default NewsCard;
