export function checkCarpetAvailablity(status: number) {
  if (!status) return false;
  const binary = Number(status).toString(2).padStart(6, "0");
  if (
    binary.charAt(2) == "1" ||
    binary.charAt(3) == "1" ||
    binary.charAt(5) == "0"
  ) {
    return false;
  }
  return true;
}
