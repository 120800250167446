import { API } from "shared";

export type RandomCarpetType = "popular" | "recommended" | "new";

export const GetCarpets = (): Promise<any> => {
  return API.get("/carpets/site-map", {});
};

export const getRandomCarpets = (type: RandomCarpetType) => {
  return API.get(`/carpet/product/random?status=${type}`);
};

export const getLandingGifs = () => {
  return API.get("/carpets/gif");
};

export const getFilteredCarpets = (params: any) => {
  return API.get("/carpets", { params });
};
