import { Loader2 } from "lucide-react";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Link from "next/link";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { throttle } from "../../../lib/throttle-utils";
import { cn } from "../../../lib/utils";
import { Button } from "../../ui/button";

const ImagesSlider = dynamic(() => import("./images-slider"), {
  ssr: false,
});

const MobileImagesSwiper = dynamic(() => import("./mobile-images-swiper"), {
  ssr: false,
});

interface INewCarpetCardProps {
  id: string;
  status: string;
  description: string;
  height: string;
  width: string;
  image: string;
  fullRated?: boolean;
  size: string;
  workshop: string;
  images?: string[];
  enableSwipeToSlideInMobile?: boolean;
}

const isTouchDevice = () => {
  return (
    typeof window !== "undefined" &&
    ("ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      "onmsgesturechange" in window)
  );
};

const NewCarpetCard = ({
  id,
  status,
  description,
  height,
  width,
  image,
  fullRated,
  size,
  workshop,
  images,
  enableSwipeToSlideInMobile,
}: INewCarpetCardProps) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const [isTouch, setIsTouch] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Detect touch devices
  useEffect(() => {
    setIsTouch(isTouchDevice());
  }, []);

  const throttledHoverStart = useCallback(
    throttle(() => {
      setSliderLoaded(true); // Trigger lazy load for slider
    }, 300), // Throttle hover effect
    []
  );

  const handleMouseEnter = () => {
    if (isTouch) return; // Skip hover logic on touch devices
    setIsHovered(true);
    hoverTimeoutRef.current = setTimeout(() => {
      throttledHoverStart();
    }, 300);
  };

  const handleMouseLeave = () => {
    if (isTouch) return; // Skip hover logic on touch devices
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setIsHovered(false);
    setSliderLoaded(false);
  };

  return (
    <Link href={`/carpets/${workshop}/${size}/${id}`} passHref>
      <a className="w-full">
        <div
          className={cn(
            "relative h-[490px] md:h-[580px] 3xl:h-[710px] w-full group overflow-hidden cursor-pointer transition-all duration-300",
            isHovered && !isTouch
              ? "scale-[1.04] z-20 shadow-md ring-1 ring-gray-300 rounded-sm"
              : ""
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Main Image */}
          <img
            src={image || "/assets/gray_image.png"}
            alt={`${description} image`}
            className="h-full w-full object-cover"
            loading="lazy"
          />

          {/* Slider (Desktop Only) */}
          {isHovered && images && images?.length > 1 && !isTouch && (
            <Suspense
              fallback={
                <Loader2 className="animate-spin size-6 stroke-primary absolute inset-1/2" />
              }
            >
              {sliderLoaded ? (
                <div className="fade-in-60 animate-in h-full w-full duration-500">
                  <ImagesSlider
                    imagesList={[...images, image].filter(Boolean)}
                    isHovered={isHovered}
                    name={description}
                  />
                </div>
              ) : (
                <div className="animate-out fade-out-30 duration-300 h-full w-full bg-black/10 blur-[2px] inset-0 absolute">
                  <Loader2 className="animate-spin size-6 stroke-primary inset-1/2 absolute" />
                </div>
              )}
            </Suspense>
          )}

          {/* {isTouch && images && images.length > 1 && (
            <MobileImagesSwiper
              imagesList={[image, ...images].filter(Boolean)}
              name={description}
              enableSwipeToSlide={enableSwipeToSlideInMobile}
            />
          )} */}

          {/* Full Rated Badge */}
          {fullRated && (
            <img
              className="absolute top-[25px] md:top-[30px] left-[15px] md:left-[30px] z-10"
              src="/assets/recoo.png"
              width={100}
              height={100}
              alt=""
            />
          )}

          {/* Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#00000000] via-[rgba(0,0,0,0.05)] to-[rgba(0,0,0,0.6)] pointer-events-none z-0" />

          {/* Text and Status */}
          <div className="absolute bottom-0 left-0 right-0 py-[25px] px-[15px] md:p-[30px] flex flex-col space-y-2 md:space-y-[15px] justify-center">
            <div
              className={`flex ${status ? "justify-between" : "justify-end"}`}
            >
              {status && (
                <span
                  className={`px-2 md:px-3 py-1 md:py-2 ${
                    status.toLowerCase() === "new"
                      ? "bg-[#06C755]"
                      : status.toLowerCase() === "sold"
                        ? "bg-[#C74006]"
                        : ""
                  } text-white text-[13px] md:text-[14px] leading-5 rounded-[5px] text-center w-contain`}
                >
                  {t(status)}
                </span>
              )}
              <span className="px-2 md:px-3 py-1 md:py-2 text-white text-[13px] md:text-[14px] leading-5">
                {t("Size")} {height} x {width}
              </span>
            </div>
            <p className="w-full flex-wrap text-[16px] md:text-[24px] text-[#FFFFFF] leading-6 md:leading-9 text-left font-semibold m-0 line-clamp-3">
              {description}
            </p>
            <Button
              asChild
              variant={"outline"}
              className="text-white border-solid text-[16px] md:text-[20px] shadow-sm rounded-none outline-none touch-none font-bold px-0 cursor-pointer !border-2 !border-[#FFFFFF] hover:drop-shadow-none group-hover:!border-primary focus:border-2 group-hover:bg-primary transition-colors duration-300"
            >
              <span className="w-full text-center py-[10.5px] md:py-[15.5px]">
                詳細を見る
              </span>
            </Button>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default NewCarpetCard;
