import * as Sentry from "@sentry/node";
import { dehydrate, QueryClient, useQuery } from "@tanstack/react-query";
import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo, SocialProfileJsonLd } from "next-seo";
import Head from "next/head";
import React, { useEffect } from "react";
import CarpetListHomePage from "../components/organisms/CarpetListHomePage";
import CustomerService from "../components/organisms/CustomerServices";
import CustomerTestimonials from "../components/organisms/CustomerTestimonials";
import LandingContactForm from "../components/organisms/LandingContactForm";
import NewArticleSection from "../components/organisms/NewArticleSection";
import NewImageCarousel from "../components/organisms/NewImageCarousel";
import ReasonForChoise from "../components/organisms/ReasonForChoise";
import ServiceSection from "../components/organisms/ServiceSection";
import StoreSection from "../components/organisms/StoreSection";
import TourSection from "../components/organisms/TourSection";
import { ILandingCarpetProps } from "../interfaces/CarpetData";
import { getFilteredArticles, getLandingImages } from "../services/articles";
import { getRandomCarpets } from "../services/carpets";
import { getPublicReviews } from "../services/reviews";

const jsonLdData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "ペルシャ絨毯専門店 ゴレスタン",
  url: "https://golestan.jp/",
};

interface ILandingImagesProps {
  coverImages: any[];
  shopImages: any[];
}

const Homepage: React.FC<ILandingCarpetProps> = () => {
  const {
    data: landingImageList,
    isError,
    error,
  } = useQuery<ILandingImagesProps>({
    queryKey: ["landing-images"],
    queryFn: async () => getLandingImages().then((res) => res.data),
  });

  useEffect(() => {
    if (isError) {
      Sentry.captureException(error);
    }
  }, [isError, error]);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
        />
      </Head>
      <NextSeo
        title={`${process.env.NEXT_PUBLIC_PAGE_TITLE}`}
        canonical={process.env.NEXT_PUBLIC_APP_URL}
        description="世界最高峰の品質とデザインを誇るシルク製ペルシャ絨毯を厳選。美しい見た目と心地良い使い心地で、暮らしに特別な彩りを添える専門店です。"
        additionalMetaTags={[
          {
            name: "keywords",
            content:
              "ペルシャ絨毯, シルク絨毯, 高級絨毯, 伝統工芸, インテリア, ゴレスタン, ラグ専門店, 高品質絨毯, 絨毯販売",
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Organization"
        name={process.env.NEXT_PUBLIC_PAGE_TITLE ?? "Golestan Japan"}
        url="https://golestan.jp"
        sameAs={["https://www.youtube.com/@jpgolestan9492"]}
      />
      <div className="max-w-[1920px] bg-[#F5F5F5] m-0 !font-dmSans">
        <NewImageCarousel
          slides={
            landingImageList?.coverImages?.length
              ? landingImageList.coverImages.map((item) => item.Image)
              : ["assets/gray_image.png"]
          }
        />
        <CarpetListHomePage />
        <ReasonForChoise />
        <ServiceSection />
        <CustomerTestimonials />
        <TourSection />
        <StoreSection
          storeImages={
            landingImageList?.shopImages?.length
              ? landingImageList.shopImages
              : []
          }
        />
        <CustomerService />
        <LandingContactForm />
        <NewArticleSection />
      </div>
    </>
  );
};

export default Homepage;

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const queryClient = new QueryClient();
  await Promise.all([
    queryClient.prefetchQuery({
      queryKey: ["random-carpets", "popular"],
      queryFn: async () => getRandomCarpets("popular").then((res) => res.data),
    }),
    queryClient.prefetchQuery({
      queryKey: ["filtered-articles"],
      queryFn: async () => getFilteredArticles().then((res) => res.data),
    }),
    queryClient.prefetchQuery({
      queryKey: ["public-reviews"],
      queryFn: async () => getPublicReviews().then((res) => res.data),
    }),
    queryClient.prefetchQuery({
      queryKey: ["landing-images"],
      queryFn: async () => getLandingImages().then((res) => res.data),
    }),
  ]);

  return {
    props: {
      ...(await serverSideTranslations(locale ?? "ja")),
      dehydratedState: dehydrate(queryClient),
    },
  };
};
