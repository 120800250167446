"use client";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../lib/utils";

export const buttonVariants = cva(
  "relative cursor-pointer inline-flex items-center justify-center text-sm whitespace-nowrap font-medium transition-all focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground enabled:hover:shadow-lg",
        outline: "bg-transparent text-black",
        secondary:
          "bg-secondary text-secondary-foreground enabled:hover:shadow-lg",
        ghost:
          "enabled:hover:bg-secondary enabled:hover:text-secondary-foreground",
        link: "text-primary bg-white border-2 border-solid border-primary",
        primary: "bg-primary-admin text-white enabled:hover:shadow-lg",
      },
      size: {
        sm: "h-6 rounded px-[7px]",
        md: "h-8 rounded-md px-[15px]",
        lg: "h-[50px] md:h-12 rounded-md px-[15px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "lg",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean | null;
  icon?: React.ReactNode;
  iconClasName?: string;
  suffixIcon?: React.ReactNode;
  suffixIconClasName?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading,
      icon,
      iconClasName,
      suffixIcon,
      suffixIconClasName,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return Comp === "button" ? (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        <>
          {loading ? "Loading.." : null}
          <>
            {icon ? (
              <span
                className={cn(
                  "absolute left-4 md:relative md:left-0 md:mr-2",
                  iconClasName
                )}
              >
                {icon}
              </span>
            ) : null}
            <span className="flex justify-center items-center">
              {props.children}
            </span>
            {suffixIcon ? (
              <span className={cn("ml-2", suffixIconClasName)}>
                {suffixIcon}
              </span>
            ) : null}
          </>
        </>
      </Comp>
    ) : (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";
