import { useTranslation } from "next-i18next";
import React from "react";
import { ContactEmail } from "../ContactEmail";

const CustomerSupport: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="grid gap-4">
      <strong className="font-semibold leading-9 text-base md:text-xl text-whiteText text-start">
        {t("Customer Support")}
      </strong>
      <div className="grid gap-5 text-whiteText !text-xl font-normal">
        <a
          href="tel:080-3578-9185"
          className="flex items-center space-x-2 !text-whiteText"
        >
          <PhoneIcon className="size-6" />
          <span>080-3578-9185</span>
        </a>
        <a
          href="tel:049-293-4004"
          className="flex items-center space-x-2 !text-whiteText"
        >
          <PhoneIcon className="size-6" />
          <span>049-293-4004</span>
        </a>
        <ContactEmail className="[&>span]:text-xl !text-whiteText [&>svg]:size-6" />
      </div>
    </div>
  );
};

export { CustomerSupport };

export const PhoneIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="36"
      viewBox="0 0 39 36"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_1132_45268)">
        <path
          d="M34.563 1.1875H4.43674C2.16786 1.1875 0.328613 2.81036 0.328613 4.81232V31.3944C0.328613 33.3963 2.16786 35.0192 4.43674 35.0192H34.563C36.8319 35.0192 38.6712 33.3963 38.6712 31.3944V4.81232C38.6712 2.81036 36.8319 1.1875 34.563 1.1875ZM8.37371 30.1861C7.77178 30.1861 7.25621 29.8181 7.12278 29.308L5.83916 24.3994C5.77147 24.1406 5.80902 23.8691 5.94523 23.6321C6.08144 23.3953 6.30767 23.2082 6.58436 23.1035L12.5752 20.8382C12.8311 20.7413 13.1158 20.7206 13.3862 20.7791C13.6566 20.8375 13.8982 20.9719 14.0745 21.162L16.7274 24.0229C20.9163 22.2889 24.2845 19.2761 26.2088 15.6572L22.9663 13.3164C22.7508 13.1608 22.5984 12.9476 22.5322 12.709C22.466 12.4704 22.4894 12.2192 22.5992 11.9935L25.1667 6.70742C25.2853 6.46329 25.4974 6.26368 25.7659 6.14349C26.0343 6.0233 26.3421 5.99017 26.6354 6.04989L32.1985 7.1825C32.4813 7.24005 32.7338 7.3805 32.9145 7.58094C33.0953 7.78138 33.1937 8.02996 33.1937 8.28611C33.1937 20.3909 22.0722 30.1861 8.37371 30.1861Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1132_45268">
          <rect
            width="38.3426"
            height="34.41"
            fill="white"
            transform="translate(0.328613 0.794922)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
