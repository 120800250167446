import React from "react";

const CustomerService = () => {
  return (
    <div className="w-auto bg-[#EDEDED] py-[30px] md:py-[100px] px-[15px] 2xl:px-0">
      <div className="max-w-[1401px] mx-auto grid sm:flex justify-center sm:flex-row sm:justify-between items-center gap-[30px]">
        <div className="max-w-[242px] w-full flex flex-col space-y-[15px] justify-center items-center">
          <img
            src={"assets/delivery_landing.svg"}
            alt=""
            height={40}
            width={92}
          />
          <div className="flex flex-col space-y-2 justify-center items-center">
            <p className="mb-0 text-[#1B2E32] text-[18px] md:text-[24px] leading-[36px] font-semibold">
              送料無料
            </p>
            <p className="mb-0 text-[#333333] text-[16px] leading-[27px] font-light text-center">
              送料無料で迅速、丁寧に包装して発送致します。
            </p>
          </div>
        </div>
        <div className="max-w-[242px] w-full flex flex-col space-y-[15px] justify-center items-center">
          <img
            src={"assets/guarantee_landing.svg"}
            alt=""
            height={40}
            width={92}
          />
          <div className="flex flex-col space-y-2 justify-center items-center">
            <p className="mb-0 text-[#1B2E32] text-[18px] md:text-[24px] leading-[36px] font-semibold">
              返品保証
            </p>
            <p className="mb-0 text-[#333333] text-[16px] leading-[27px] font-light text-center">
              商品をお受取り頂いた日から5日以内にご返品が可能です。
            </p>
          </div>
        </div>
        <div className="max-w-[242px] w-full flex flex-col space-y-[15px] justify-center items-center">
          <img
            src={"assets/confirmation_landing.svg"}
            alt=""
            height={40}
            width={92}
          />
          <div className="flex flex-col space-y-2 justify-center items-center">
            <p className="mb-0 text-[#1B2E32] text-[18px] md:text-[24px]  leading-[36px] font-semibold">
              実物確認
            </p>
            <p className="mb-0 text-[#333333] text-[16px] leading-[27px] font-light text-center">
              実際に絨毯をご確認頂けます。 お問い合わせください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerService;
