import { Phone } from "lucide-react";
import { useTranslation } from "next-i18next";
import React from "react";
import { cn } from "../../../lib/utils";
interface Props {
  phoneNumber?: string;
  className?: string;
}

const ContactCall: React.FC<Props> = ({ phoneNumber, className }) => {
  const { t } = useTranslation();

  return (
    <a
      href={`tel:${phoneNumber}`}
      className={cn(
        "flex items-center space-x-1 xs:space-x-2 bg-primary md:bg-transparent px-3 md:px-0 h-auto !text-white",
        className
      )}
    >
      <Phone className="size-4 xs:size-5 stroke-0 fill-white" />
      <span className="text-xs xs:text-sm font-medium whitespace-nowrap">
        {phoneNumber}
      </span>
    </a>
  );
};

export { ContactCall };
