import AutoplayPlugin from "embla-carousel-autoplay";
import React from "react";
import { Button } from "../../ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselThumbs,
} from "../../ui/carousel";

interface IStoreSectionProps {
  storeImages: {
    Image: string;
    ImageType: string;
    SortOrder: number;
    id: number;
  }[];
}

const StoreSection: React.FC<IStoreSectionProps> = ({ storeImages }) => {
  return (
    <div className="w-auto] bg-white pt-[30px] md:pt-[100px]">
      <div className="max-w-[1401px] mx-auto flex flex-col space-y-[15px] md:space-y-[30px] p-4 md:p-2 2xl:p-0">
        <h2 className="text-primary text-[24px] md:text-[40px] leading-[60px] font-black text-left mb-0">
          店舗
        </h2>
        <div className="flex space-y-[30px] md:space-y-0 md:space-x-[30px] xl:space-x-0 xl:gap-[60px] 2xl:gap-[96px] flex-col md:flex-row">
          <div className="md:min-w-[500px] max-w-[728px] w-full  h-full md:min-h-[485px]  max-h-[485px] ">
            {storeImages.length > 0 && (
              <Carousel
                opts={{
                  loop: true,
                  align: "center",
                }}
                plugins={[
                  AutoplayPlugin({
                    delay: 3000,
                  }),
                ]}
              >
                <CarouselContent>
                  {storeImages.map((item) => (
                    <CarouselItem key={`store-${item.id}`}>
                      <img
                        src={item.Image}
                        alt={`Store Image ${item.id}`}
                        className="h-full object-cover block w-full md:min-h-[485px] md:min-w-[500px] max-h-[485px]"
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselThumbs />
              </Carousel>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-[24px] md:text-[38px] leading-[45px] font-black mb-0">
              最高峰ペルシャ絨毯在庫するショールーム
            </h3>
            <div className="flex space-x-[10px] justify-start">
              <img
                src="assets/location.svg"
                alt=""
                width={20}
                height={20}
                className="fill-[#5F5F5F]"
              />
              <div className="text-[#333333] text-[16px] leading-6">
                <p className="mb-0">〒354-0036</p>
                <p className="mb-0">埼玉県富士見市ふじみ野東３丁目１４−１</p>
              </div>
            </div>
            <div className="flex space-x-[10px] justify-start">
              <img
                src="assets/clock.svg"
                alt=""
                width={20}
                height={20}
                className="fill-[#5F5F5F]"
              />
              <div className="text-[#333333] text-[16px] leading-6">
                <p className="mb-0">営業時間 AM10:00～PM5:00</p>
              </div>
            </div>
            <div className="flex space-x-[10px] justify-start">
              <img
                src="assets/newPhone.svg"
                alt=""
                width={18}
                height={18}
                className="fill-[#5F5F5F]"
              />
              <div className=" text-[16px] leading-6 cursor-pointer">
                <a
                  href="tel:049-293-4004"
                  className="mb-0 !underline !text-[#333333] hover:!text-primary"
                >
                  049-293-4004
                </a>
              </div>
            </div>
            <div className="flex space-x-[10px] justify-start">
              <img
                src="assets/doctor.svg"
                alt=""
                width={18}
                height={18}
                className="fill-[#5F5F5F]"
              />
              <div className="text-[#333333] text-[16px] leading-6">
                <p className="mb-0">
                  東武東上線「池袋駅」から直通30分、ふじみ野駅東口徒歩7分
                </p>
              </div>
            </div>
            <div className="flex space-x-[10px] justify-start">
              <img
                src="assets/parking.svg"
                alt=""
                width={18}
                height={18}
                className="fill-[#5F5F5F]"
              />
              <div className="text-[#333333] text-[16px] leading-6">
                <p className="mb-0">駐車場完備でお車でも安心です。</p>
              </div>
            </div>
            <p className="mb-0 text-[#1B2E32]  text-[16px] leading-[19px] font-light mt-2">
              ショールームでは、絨毯の美しさや繊細な職人技を直接ご体感いただけます。
              以下のボタンから空き状況をご確認いただき、簡単にご予約いただけます。
              ぜひお気軽にお越しください。
            </p>
            <div className="flex justify-start items-start w-full">
              <Button
                asChild
                variant={"default"}
                className="border-none w-full sm:w-auto text-[16px] leading-[27px] rounded-none hover:opacity-90 text-white opacity-1 bg-primary cursor-pointer px-[10px] py-[10.5px] font-bold"
              >
                <a
                  href="https://timerex.net/s/support_d67a_0fb3/8f8d5baa"
                  target="_blank"
                  className="!text-white"
                >
                  空き状況の確認と予約する
                </a>
              </Button>
            </div>
            <p className="mb-0 text-[#5F5F5F] text-[14px] leading-[16px] font-light">
              ※当日のご予約も可能です。
            </p>
          </div>
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12933.436334089689!2d139.5256891!3d35.8647162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018dd81d7d7d42f%3A0xac40039068687429!2z44Oa44Or44K344Oj57Wo5q-v5bCC6ZaA5bqXfEdvbGVzdGFuKOOCtOODrOOCueOCv-ODsyk!5e0!3m2!1sja!2sjp!4v1733815035020!5m2!1sja!2sjp"
            width="100%"
            height="497"
            className="border-none w-full h-[497px]"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default StoreSection;
