export function pickRandomItems(array: string[], maxItems = 20) {
  const result = [];
  const arrayLength = array.length;

  // Limit the number of items to the array length or maxItems, whichever is smaller
  const limit = Math.min(maxItems, arrayLength);

  // Fisher-Yates shuffle logic to select `limit` items
  for (let i = 0; i < limit; i++) {
    const randomIndex = Math.floor(Math.random() * (arrayLength - i));
    result.push(array[randomIndex]);

    // Swap the picked element with the last unshuffled element
    [array[randomIndex], array[arrayLength - i - 1]] = [
      array[arrayLength - i - 1],
      array[randomIndex],
    ];
  }
  return result;
}
