import { Button } from "../../ui/button";
import React from "react";

const TourSection = () => {
  return (
    <div className="w-full bg-[#19292C]">
      <div className="max-w-[1579px] mx-auto flex flex-col sm:flex-row space-y-[30px] sm:space-y-0 sm:space-x-[30px] md:space-x-0 md:gap-[48px] p-[24px] md:p-[50px]">
        <iframe
          width="448px"
          height="282px"
          src="https://my.matterport.com/show/?m=RauHQqdw4Xe"
          frameBorder="0"
          allowFullScreen
          allow="xr-spatial-tracking"
          className=" w-full sm:w-[448px] h-[282px]"
        ></iframe>

        <div className="max-w-[905px] flex flex-col space-y-3 justify-start items-start">
          <h2 className="text-[#FFFFFF] text-[24px] md:text-[38px] leading-[27px] md:leading-[50px] font-black mb-0">
            都内で開催された展示会の様子を、バーチャルツアーでお楽しみいただけます。
          </h2>

          <p className="text-[16px] leading-[34px] font-medium mb-0 text-[#FFFFFF]">
            「このツアーでは、展示スペースの奥深くまで踏み入り、普段は目にすることが難しい厳選されたペルシャ絨毯のコレクションをじっくりとご覧いただけます。職人の技が光る繊細なデザインや鮮やかな色彩、そしてそれぞれの絨毯が持つ特別な物語を、まるでその場にいるかのようにご体感いただけます。
          </p>

          <div className="flex justify-start items-center w-full">
            <Button
              asChild
              variant={"default"}
              className="border-none w-full sm:w-auto text-[14px] md:text-[16px] leading-[27px] hover:opacity-90 rounded-none text-white opacity-1 bg-primary cursor-pointer p-0 font-bold"
            >
              <a
                href="https://my.matterport.com/show/?m=RauHQqdw4Xe"
                target="_blank"
                className="!text-white px-[37px] py-[10.5px]"
              >
                Take a tour
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourSection;
