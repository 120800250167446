/* eslint-disable import/namespace */
import * as Sentry from "@sentry/node";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import "antd/dist/antd.css";
import { ArrowUpIcon } from "lucide-react";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import { setCookie } from "nookies";
import React, { useCallback, useEffect, useState } from "react";
import { API, firebase, setToken } from "shared";
import { CartContext } from "../cart_context";
import { Container } from "../components";
import "../main.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: boolean;
  setUser: any;
  decodedToken: any;
  setDecodedToken: any;
  setLoading: any;
  userData: UserData;
};

interface UserData {
  buildingMansionName?: string;
  city?: string;
  createdAt?: string;
  email: string;
  firstName: string;
  firstNameKatakana: string;
  lastName: string;
  lastNameKatakana: string;
  phoneNumber?: string;
  postalCode?: string;
  prefecture?: string;
  streetName?: string;
  updatedAt?: string;
  userId: string;
}

// Extend AppProps to include 'dehydratedState'
interface CustomAppProps extends AppProps {
  pageProps: AppProps["pageProps"] & {
    dehydratedState?: unknown; // React Query's state type
  };
  Component: AppProps["Component"] & {
    skipGlobalScrollRestoration?: boolean;
  };
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `app-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
  const logEvent = (url: string) => {
    firebase?.analytics().setCurrentScreen(url);
    firebase?.analytics().logEvent("screen_view", {
      screen_name: url,
      app_name: "Golestan",
    });
  };

  Router.events.on("routeChangeComplete", (url) => {
    logEvent(url);
  });

  logEvent(window.location.pathname);
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});

const MyApp = ({ Component, pageProps }: CustomAppProps) => {
  const router = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 1000,
            retry: false,
          },
        },
      })
  );
  const [cartCounter, setCartCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as firebase.User | null);
  const [userData, setUserData] = useState({} as UserData);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    initialLoad();
    const cartItems = localStorage.getItem("cart");
    if (cartItems) {
      const carts = JSON.parse(cartItems);
      setCartCounter(carts.filter((i: number) => Number.isInteger(i)).length);
    } else {
      setCartCounter(0);
    }
  }, []);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    const handleRouteChangeStart = () => {
      // Skip saving scroll position for flagged routes
      if (!Component.skipGlobalScrollRestoration) {
        sessionStorage.setItem(
          `scrollPosition-${router.asPath}`,
          window.scrollY.toString()
        );
      }
    };

    const handleRouteChangeComplete = (url: string) => {
      // Skip restoring scroll position for flagged routes
      if (!Component.skipGlobalScrollRestoration) {
        const savedScrollPosition = sessionStorage.getItem(
          `scrollPosition-${url}`
        );
        if (savedScrollPosition) {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
          sessionStorage.removeItem(`scrollPosition-${url}`);
        }
      }
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router, Component]);

  const initialLoad = () => {
    firebase?.auth().onAuthStateChanged(async (user) => {
      try {
        setUser(user);
        if (user !== null) {
          setUser(user);
          const idToken = await user!.getIdToken();
          setCookie(null, "accessToken", idToken, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          });
          setToken(idToken);

          const userDetails: any = await API.get("/users/info");
          if (userDetails && userDetails.data) {
            const userResponse = userDetails.data;
            setUserData({
              firstName: userResponse.first_name || "",
              buildingMansionName: userResponse.building_mansion_name || "",
              city: userResponse.city || "",
              createdAt: userResponse.created_at || "",
              email: userResponse.email || "",
              firstNameKatakana: userResponse.first_name_katakana || "-",
              lastName: userResponse.last_name || "",
              lastNameKatakana: userResponse.last_name_katakana || "-",
              phoneNumber: userResponse.phone_number || "",
              postalCode: userResponse.postal_code || "",
              prefecture: userResponse.prefecture || "",
              streetName: userResponse.street_name || "",
              updatedAt: userResponse.updated_at || "",
              userId: userResponse.user_id || "",
            });
          }
        }
      } catch (e) {
        //console.log(e)
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    // Throttling logic
    let timeoutId: NodeJS.Timeout | null = null;

    const handleScroll = () => {
      if (timeoutId) return; // Skip execution if throttled

      timeoutId = setTimeout(() => {
        const scrollThreshold = document.documentElement.scrollHeight / 5; // 1/5th of the page height
        const currentScroll = window.scrollY;

        // Update visibility only if it needs to change
        if (currentScroll > scrollThreshold && !isVisible) {
          setIsVisible(true);
        } else if (currentScroll <= scrollThreshold && isVisible) {
          setIsVisible(false);
        }

        timeoutId = null; // Reset the throttle
      }, 300); // Throttle interval in milliseconds
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVisible]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <DefaultSeo
        facebook={{
          appId: "2747726002141483",
        }}
        description="ペルシャ絨毯の中でも世界最高峰の品質とデザインであり見て美しく使うと心地良いシルク絨毯には. シルクロードの文化や歴史そして精神までも紡がれています。国内発送-無料配送-返品保証-最安値"
        defaultOpenGraphImageHeight={630}
        defaultOpenGraphImageWidth={1200}
        openGraph={{
          type: "website",
          locale: "ja_JP",
          siteName: "ペルシャ絨毯専門店 ゴレスタン",
          images: [
            {
              url: "https://golestan.jp/assets/seo.png",
              alt: process.env.NEXT_PUBLIC_PAGE_TITLE ?? "",
              type: "image/png",
            },
          ],
        }}
        twitter={{
          cardType: "summary",
        }}
        additionalMetaTags={[
          {
            name: "viewport",
            content: "initial-scale=1.0, width=device-width",
          },
        ]}
      />
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps?.dehydratedState ?? null}>
          <AuthContext.Provider
            value={{
              loading,
              user,
              authenticated: user !== null,
              userData,
              setUser,
              setLoading,
            }}
          >
            <Container cartCounter={cartCounter}>
              <CartContext>
                <Component setCartCounter={setCartCounter} {...pageProps} />
              </CartContext>
            </Container>
          </AuthContext.Provider>
        </HydrationBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      {isVisible && (
        <abbr
          title="scroll to top"
          className="animate-in slide-in-from-bottom-1 z-[55] fixed bottom-10 right-10 "
        >
          <ArrowUpIcon
            aria-label="Scroll to top"
            onClick={scrollToTop}
            className="bg-primary p-3 size-12 rounded-full stroke-white drop-shadow-md cursor-pointer hover:drop-shadow-lg transition-all duration-300 opacity-70 hover:opacity-100"
          />
        </abbr>
      )}
    </>
  );
};

export default appWithTranslation(MyApp);
