import { UserCircleIcon } from "lucide-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";

const Login: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Link href="/sign-up" passHref>
      <a className="flex items-center space-x-2 !text-body hover:opacity-90 duration-200">
        <UserCircleIcon className="size-5 md:size-6" />
        <span className="font-medium leading-[30px] tracking-[0.3px]">
          {t("Login")}
        </span>
      </a>
    </Link>
  );
};

export { Login };
