import { API } from "shared";

export const getPublicReviews = () => {
  return API.get("/reviews/public");
};

export const GetReviewsList = (data: any): Promise<any> => {
  return API.get("/reviews", {
    params: data,
  });
};
