export const yahooTestimonials = [
  {
    name: "tenkie（533）",
    message: "この度も良品を有難うございました。大切に致します。",
  },
  {
    name: "w650（451）",
    message:
      "憧れの手織りペルシャ絨毯を遂に購入出来ました。大切に使わせて頂きます。またペルシャ絨毯を購入する際は宜しくお願い致します。良い商品を安く出品して頂き有難う御座いました。",
  },
  {
    name: "Tora（13）",
    message: "迅速に対応していただきました。",
  },
  {
    name: "ish********（96）",
    message:
      "迅速な対応ありがとうございました。とても良い取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "znb********（15）",
    message:
      "素晴らしいペルシャ絨毯です。早速タペストリーとして掛させて戴きました。有難うございました。",
  },
  {
    name: "kum********（293）",
    message:
      "とても素敵な商品ありがとうございました。壁に飾ろうと思います。とても良い取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "UTAN（196）",
    message: "有難うございました！とても美しい作品でサイコーです！",
  },
  {
    name: "mso********（113）",
    message:
      "ありがとうございました。とても良い取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "亀井彰久（9）",
    message: "信頼できる業者さんです。",
  },
  {
    name: "Luketakeru（541）",
    message: "本日受け取りました。ありがとうございました。",
  },
  {
    name: "ルシアン（312）",
    message:
      "ありがとうございました。とても良い品で気持ちよく取引ができました。また予算ができましたら取引させて頂きたく思います。よろしくお願いいたします。",
  },
  {
    name: "ara********（164）",
    message:
      "連絡遅くなりすみません。ありがとうございました。とても良い取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "優（新規）",
    message:
      "お世話になっております。改めまして、お取引完了までどうぞ宜しくお願いいたします。",
  },
  {
    name: "ｋ**i***k（170）",
    message:
      "ありがとうございました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "boo********（81）",
    message:
      "手入れや意匠等の質問に非常に丁寧に対応してもらえます。品物は極めて優良、品を考えると値段は極めて安価です。他の海外絨毯販売の店と比較すると、知る人ぞ知る秘密の優良店になり得ると思います。",
  },
  {
    name: "ark********（286）",
    message:
      "これ以上の良品を見たことがないと言うレベルの素晴らしい商品です。ありがとうございました。とても良い取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "mk1********（29）",
    message: "迅速な対応有難うございました。",
  },
  {
    name: "gen********（201）",
    message:
      "ありがとうございました。可愛いお品物で、喜んでおります。すぐにお手配くださり、とても良い取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "jum********（229）",
    message:
      "このたびは、迅速かつ丁寧なご対応、誠にありがとうございました。先ほど無事に受けとりました。深い色合いと艶、細かい模様が想像以上に美しく、とても嬉しいです。大切に飾らせていただきます。ありがとうございました。",
  },
  {
    name: "^_^（25）",
    message: "とても綺麗な商品でした。対応も非常に気持ち良いです。",
  },
  {
    name: "kyo********（1）",
    message: "素敵な品物でした。お取引ありがとうございました。",
  },
  {
    name: "11andy（375）",
    message:
      "ありがとうございました。とても良い取引ができました。また機会が有りましたら、よろしくお願いいたします。良い絨毯で、今後出にくいデザインとの事、父親の米寿の祝いにプレゼントします。この縁を嬉しく思います。(*^^*)",
  },
  {
    name: "くぼやまきよと（86）",
    message: "イイ商品をありがとうございました、大事に使いたいと思います。",
  },
  {
    name: "kom********（47）",
    message:
      "敏速で丁寧な対応をしていただき、安心して取引ができました。また機会がありましたら、よろしくお願いいたします。",
  },
  {
    name: "小野寺 泰博（289）",
    message:
      "本日早速飾りました、絨毯の鮮やかな色使いが素晴らしいです。ロココ調のリビングに違和感なく調和しました。",
  },
  {
    name: "dag********（60）",
    message:
      "丁寧で迅速な御対応、ありがとうございました。また機会がありましたら、宜しくお願いします。",
  },
  {
    name: "メルエム（29）",
    message:
      "TVで見て急に欲しくなり初めて買って見たのですが素晴らしい芸術品だと思いました。迅速な対応ありがとうございました！",
  },
  {
    name: "sin********(715)",
    message:
      "とても良い商品でした。対応も早く安心して取引完了しました。薔薇の柄が好きなので、また機会があれば宜しくお願い致します。",
  },
  {
    name: "ykm********(39)",
    message:
      "迅速丁寧な対応で信頼できる出品者様でした。商品大事に飾らせて頂きます。有難うございました。",
  },
  {
    name: "おんちくん(310)",
    message:
      "届きました。「すばらしい、見事な芸術品」です。（家宝として）大切に飾らせていただきます。本当にありがとうございました。",
  },
  {
    name: "snq********(320)",
    message:
      "商品無事に受け取り、有り難く感謝しております。此れで貴殿から購入した作品も早いモノで4作目になり、大切にして飾りたいと考えます。又、機会が有りましたら参加させて下さい！！",
  },
  {
    name: "ルナルナ(1223)",
    message:
      "大変綺麗で喜んでおります。心に潤いを持たせてくれるような素敵な絨毯でした。機会がありましたら又よろしく願い致します。",
  },
  {
    name: "sho********（39）",
    message:
      "非常に丁寧なご対応誠ありがとうございました。とてもスムーズで不安も無く、良い取引ができました。またリピートしたいと思います。素晴らしい絨毯、家宝にしたいと思います。",
  },
  {
    name: "もりかげよしき（2）",
    message:
      "ありがとうございました。とても良い買い物をさせて頂きました。ネットで見た感じもいいのですが、商品が届き実物を手に取って見ると凄くいいので、玄関マットに使用するのがもったいくらいです。今では、私のお気に入りです。",
  },
  {
    name: "mad********（15）",
    message:
      "対応も非常に丁寧でとても満足しています。この度は本当にありがとうございました。",
  },
  {
    name: "gon********（155）",
    message:
      "ありがとうございました。とても良い取引ができました。素敵な絨毯をいつもありがとうございます。",
  },
  {
    name: "mut********（283）",
    message:
      "本日商品が届きました。迅速かつ丁寧な対応ありがとうございました。とても良い取引ができました。素晴らしい絨毯で感激しました。また機会が有りましたら、よろしくお願いいたします。",
  },
  {
    name: "epl********（596）",
    message:
      "お品物到着しました、スムーズで終始安心出来ました お品も素敵です 有難うございました、また機会があれば宜しくお願いします。",
  },
  {
    name: "kom********（13）",
    message:
      "とても嬉しいです。緻密なシルク絨毯を壁飾りに致します。本物で格安を確信出来て、額縁に入れて楽しみたいと思います。ありがとうございました。",
  },
  {
    name: "oku********（89）",
    message:
      "ネットの写真だけでしたので心配しましたが、明るいところで見たときの華やかさに満足です。有り難うございました。",
  },
];

export const mercariTestimonials = [
  {
    name: "akiba",
    message:
      "私にとっては高価な買い物で不安もありましたが対応がとても良く更に届いた商品は思っていた以上に素敵で大満足です。写真より素敵だとのコメントがありましたがまさしくその通りでした。ありがとうございました。ご縁があって良かったです。",
  },
  {
    name: "なっち",
    message:
      "このたびは、早速に発送頂き、ありがとうございました。可愛らしくて、気に入り、壁に飾らせて頂きました。",
  },
  {
    name: "ハナコ",
    message:
      "とてもステキです。もう少し大きなサイズがあればほしいです、3メートル位のシルクありませんか？",
  },
  {
    name: "むむまま",
    message: "素敵なご縁をありがとうございましたm(_ _)m♡",
  },
  {
    name: "ちゅんみる☆",
    message:
      "たった今届きました。素敵なお品を安く購入出来て良かったです。ありがとうございました♪",
  },
  {
    name: "ミンミンちゃん",
    message:
      "先程受取り中身確認致しました。素晴らしい織物ですね。伝統のテーマ？モチーフのようですが、作品から若さ？斬新な創造力を感じます。眺めているとパワー充電出来る感じ、いつも眺められる場所考えたいと思いました。ステキな、絨毯ありがとうございました。",
  },
  {
    name: "かのん⭐︎プロフをご覧ください。",
    message:
      "柄が大変細かく繊細で肌触りもとても良いです。購入して良かったととても満足です。本当に有り難うございました。",
  },
  {
    name: "リツリン",
    message:
      "繊細で美しいお品で大変気に入っております。この度は迅速ご丁寧なご対応ありがとうございました。又機会がございましたら宜しくお願い致します。",
  },
  {
    name: "リツリン",
    message:
      "とても緻密で素敵なお品で、大変気に入っております。この度は迅速ご丁寧なご対応ありがとうございました。又機会がございましたら、宜しくお願い致します。",
  },
  {
    name: "キューバのコーヒー",
    message:
      "どちらも素晴らしいです。末永く家族と共に暮らしの中にあると思います。ありがとうございました。",
  },
  {
    name: "キューバのコーヒー",
    message:
      "素晴らしいものでした、大きさもしっかりあって大人も実用使い出来ますね、ありがとうございました。",
  },
  {
    name: "ci",
    message:
      "今回もステキなお品をありがとうございました！また機会がありましたら宜しくお願い致します。新商品も販売されることがもしありましたら、楽しみにしたいと思います。",
  },
  {
    name: "花かんざし",
    message:
      "到着しました。今回は、丁寧な対応ありがとうございました。また機会があればお願い致します。",
  },
  {
    name: "める",
    message:
      "golestan＿jp様  この度は、丁寧でスムーズなご対応となによりも美しい絨毯に届いてからずっと見惚れていました。٩(๑❛ᴗ❛๑)۶ お心遣いまで頂きなんと言って良いのか。。♡心より感謝致します。 また機会がありましたら是非宜しくお願い致します。 ありがとうございました。♡",
  },
  {
    name: "かちゅ",
    message:
      "この度は誠にありがとうございました。商品も素晴らしく、満足しています。",
  },
  {
    name: "Mie",
    message:
      "素敵な絨毯で、とても気に入りました。 本当にありがとうございました。",
  },
  {
    name: "フラン",
    message: "商品到着しました。 本当に素敵な絨毯です。 有難う御座いました。",
  },
  {
    name: "chachan1121",
    message:
      "本日届きました。丁寧なご対応と素敵なお品をありがとうございました。大切に致します。",
  },
  {
    name: "のうちゃん",
    message:
      "無事受け取りました！ 早急にお手配くださりありがとうございました。 そして、可愛いバッグに入っていて嬉しかったです。 大切に使わせていただきます。 ありがとうございました！",
  },
  {
    name: "タスマニア",
    message:
      "本日無事商品を受け取りました。とても素敵なお品をありがとうございます。こちらのわがままにもかかわらず終始丁寧なご対応をしてくださって本日にありがとうございました！またご縁がありましたらどうぞよろしくお願いいたします。",
  },
  {
    name: "フラフラ",
    message:
      "この度はありがとうございました。 また機会があれば是非よろしくお願いいたします_(._.)_",
  },
  {
    name: "メチャ",
    message:
      "迅速丁寧なご対応いただき、ありがとうございます！ リュックは画像の色目より少し暗めでしたが、ミニ敷物と合わせてよいお品でした！！ また機会がございましたら、よろしくお願いします☺︎",
  },
  {
    name: "ci",
    message:
      "この度はとても素敵なお品をありがとうございました。また出品されるのを楽しみにしています！",
  },
  {
    name: "柴犬",
    message:
      "この度はとても丁寧でスムーズなお取引をして頂きありがとうございました！ 商品もとてもとても気に入りました！ またご縁がありましたら、どうぞ宜しくお願い致します。",
  },
];
