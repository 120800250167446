import { array } from "@storybook/addon-knobs";

const truncateText = (text: string, limit = 10): string => {
  if (!text) return "";
  return text.length > limit ? `${text.substring(0, limit - 3)}...` : text;
};

function convertArrayToString(data: string[]): string {
  return data.join(",");
}

const findFullRated = (...evaluations: number[]): boolean => {
  return evaluations.every((evaluation) => evaluation === 5);
};

const getStatusArray = (status: number): string[] => {
  const arrayStatus = [];
  const binary = Number(status).toString(2).padStart(6, "0");
  {
    status && binary.charAt(0) == "1" && arrayStatus.push("special");
  }
  {
    status && binary.charAt(1) == "1" && arrayStatus.push("mirmehdi");
  }
  {
    status && binary.charAt(2) == "1" && arrayStatus.push("sold");
  }
  {
    status && binary.charAt(3) == "1" && arrayStatus.push("reserved");
  }
  {
    status && binary.charAt(4) == "1" && arrayStatus.push("new");
  }
  {
    status && binary.charAt(5) == "1" && arrayStatus.push("public");
  }
  {
    status && binary.charAt(5) == "0" && arrayStatus.push("private");
  }

  return arrayStatus;
};

export { truncateText, convertArrayToString, findFullRated, getStatusArray };
