import React from "react";
import ImagesSlideShow from "./images-slideshow";

const ReasonForChoise = () => {
  return (
    <div className="w-auto pt-[30px] md:pt-[100px] pb-[64px] bg-[#F9F9F9]">
      <section className="max-w-[1404px] mx-auto flex flex-col sm:flex-row justify-center space-y-[15px] sm:space-y-0 sm:space-x-[15px] md:space-x-0 md:gap-[30px] lg:gap-[60px] p-4 xl:p-0">
        <div className="flex justify-center">
          <ImagesSlideShow />
        </div>
        <div className="w-full max-w-full lg:max-w-[951px] flex flex-col space-y-[15px] md:space-y-[30px]">
          <div>
            <h2 className="text-primary text-[28px] md:text-[40px] leading-[36px] md:leading-[60px] font-black mb-0 text-center sm:text-left">
              ゴレスタンが選ばれる理由
            </h2>
            <p className=" text-[24px] md:text-[38px] leading-[32px] md:leading-[45px] font-black mb-0 text-center sm:text-left">
              一枚一枚に込められた時を超える優美さ
            </p>
          </div>

          <p className="text-[16px] leading-[24px] font-medium mb-0">
            「ゴレスタン」はペルシャ語で「花畑」を意味します。その名の通り、美しさと伝統を宿したペルシャ絨毯を厳選し、皆さまにお届けしています。一枚一枚が日常に特別な魅力と満足感をもたらすよう、丁寧に選び抜かれています。
          </p>

          <div className="flex flex-col space-y-[12px] md:space-y-[17px]">
            <div>
              <h2 className="text-[16px] leading-[24px] font-semibold mb-0">
                厳選された手織りの名品
              </h2>
              <p className="text-[16px] leading-[24px] font-medium mb-0">
                当店のコレクションはすべて熟練した職人が手織りで仕上げた芸術品です。最高峰の工房で生み出された作品だけを厳選し、お客様にご提供しています。
              </p>
            </div>

            <div>
              <h2 className="text-[16px] leading-[24px] font-semibold mb-0">
                直接仕入れによる適正価格
              </h2>
              <p className="text-[16px] leading-[24px] font-medium mb-0">
                最高峰の工房と直接提携し、中間マージンを省くことで、高品質なペルシャ絨毯を適正価格でお届けしています
              </p>
            </div>

            <div>
              <h2 className="text-[16px] leading-[24px] font-semibold mb-0">
                信頼のメンテナンスサービス
              </h2>
              <p className="text-[16px] leading-[24px] font-medium mb-0">
                現地の工房でのクリーニングや修復など、熟練した職人による丁寧なアフターケアを提供しています。長く安心してお使いいただけます。
              </p>
            </div>

            <div>
              <h2 className="text-[16px] leading-[24px] font-semibold mb-0">
                シルク絨毯の名産地クムの工房と提携
              </h2>
              <p className="text-[16px] leading-[24px] font-medium mb-0">
                シルクペルシャ絨毯で名高いクムの工房と直接連携し、豊富な経験とグローバルなネットワークを活かして、信頼性の高い逸品をお届けします。
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReasonForChoise;
