import Link from "next/link";
import React from "react";
import { cn } from "../../../lib/utils";

interface Props {
  text?: string;
  className?: string;
}

const ContactEmail: React.FC<Props> = ({
  text = "お問い合わせ",
  className,
}) => {
  return (
    <Link passHref href="/contact">
      <a
        className={cn(
          "flex items-center space-x-1 xs:space-x-2 !text-white",
          className
        )}
      >
        <EmailIcon className="size-4 xs:size-5" />
        <span className="text-xs xs:text-sm font-medium whitespace-nowrap">
          {text}
        </span>
      </a>
    </Link>
  );
};

export { ContactEmail };

export const EmailIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_1142_24552)">
        <path
          d="M7.03123 8.42635H12.9688C13.2968 8.42635 13.5625 8.15365 13.5625 7.81695V7.2076C13.5625 6.8709 13.2968 6.5982 12.9688 6.5982H7.03123C6.70321 6.5982 6.4375 6.8709 6.4375 7.2076V7.81695C6.4375 8.15365 6.70321 8.42635 7.03123 8.42635ZM6.4375 11.4732C6.4375 11.8098 6.70321 12.0826 7.03123 12.0826H12.9688C13.2968 12.0826 13.5625 11.8098 13.5625 11.4732V10.8638C13.5625 10.5272 13.2968 10.2544 12.9688 10.2544H7.03123C6.70321 10.2544 6.4375 10.5272 6.4375 10.8638V11.4732ZM10 16.0865C9.39069 16.0865 8.78132 15.8938 8.26106 15.508L0.5 9.754V17.8716C0.5 18.8812 1.29748 19.6997 2.28125 19.6997H17.7188C18.7025 19.6997 19.5 18.8812 19.5 17.8716V9.754L11.7389 15.508C11.2187 15.8934 10.6093 16.0865 10 16.0865ZM18.8176 6.4059C18.4892 6.1416 18.1778 5.8929 17.7188 5.53715V3.85605C17.7188 2.8464 16.9213 2.02794 15.9375 2.02794H13.0597C12.9469 1.94415 12.8418 1.86569 12.7242 1.7781C12.1004 1.31079 10.8609 0.186495 10 0.199825C9.13906 0.186495 7.89996 1.31079 7.27579 1.7781C7.15818 1.86569 7.05315 1.94415 6.94032 2.02794H4.0625C3.07873 2.02794 2.28125 2.8464 2.28125 3.85605V5.53715C1.82221 5.8925 1.51086 6.1416 1.18244 6.4059C0.969863 6.57685 0.797957 6.7952 0.679726 7.04445C0.561495 7.29365 0.500037 7.5672 0.5 7.8444V8.25L4.0625 10.8912V3.85605H15.9375V10.8912L19.5 8.25V7.8444C19.5 7.283 19.2484 6.75245 18.8176 6.4059Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1142_24552">
          <rect
            width="19"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
