import React from "react";
import { createUseStyles } from "react-jss";
import { Footer } from "../Footer";
import { Header } from "../Header";

const useStyles = createUseStyles({
  outerWrapper: {
    maxWidth: 1920,
    margin: "0 auto",
  },
  contentWrapper: {
    maxWidth: 1920,
    width: "100%",
    margin: "0 auto",
  },
});

interface Props {
  children: any;
  cartCounter: number;
}

const Container: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Header cartCounter={props.cartCounter} />
      <div className={classes.contentWrapper}>{props.children}</div>
      <div className={classes.outerWrapper}>
        <Footer />
      </div>
    </div>
  );
};

export { Container };
