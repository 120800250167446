import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import React from "react";
import Marquee from "react-fast-marquee";
import { IReviewsProps } from "../../../interfaces/CarpetData";
import { getPublicReviews } from "../../../services/reviews";
import { Button } from "../../ui/button";
import RatingCard from "../RatingCard";
import {
  mercariTestimonials,
  yahooTestimonials,
} from "./customerTestimonialList";
import TestimonialsCard from "./TestimonialsCard";

const CustomerTestimonials: React.FC = () => {
  const settings = {
    pauseOnHover: false,
    gradient: false,
    speed: 80,
    autoFill: true,
  };

  const { data: reviews } = useQuery<IReviewsProps[]>({
    queryKey: ["public-reviews"],
    queryFn: async () => getPublicReviews().then((res) => res.data),
  });

  return (
    <div className="w-full flex flex-col space-y-[30px] pt-[30px] sm:pt-[60px] md:pt-[100px] pb-[40px] sm:[pb-63px] bg-white">
      <div className="max-w-[1400px] w-full mx-auto space-y-[60px]">
        <div className="space-y-[30px] lg:space-y-[36px] mx-auto">
          <h5 className="text-[16px] md:text-[40px] leading-6 md:leading-[60px] font-black text-primary mb-0 text-center">
            お客様の声
          </h5>
          <div className="flex flex-col w-full space-y-[30px] lg:space-y-[60px] px-4 md:px-2 xl:px-[220px]">
            {reviews &&
              reviews?.length > 0 &&
              reviews.map((review, index) => (
                <TestimonialsCard
                  key={review.id}
                  image={review.image}
                  review={review.review}
                  isEven={index % 2 === 0}
                  id={review.id}
                />
              ))}
          </div>
        </div>
        <div className="flex justify-center items-center px-4 md:px-2">
          <Button
            asChild
            variant={"default"}
            className="border-none w-full sm:w-auto text-[16px] sm:text-[20px] hover:opacity-90 leading-[27px] rounded-none text-white opacity-1 bg-primary cursor-pointer p-0"
          >
            <div>
              <Link passHref href="/customer-voice">
                <a className="!text-white px-[49.5px] py-[10.5px] sm:py-[15.5px]">
                  お客様の声をもっと見る
                </a>
              </Link>
            </div>
          </Button>
        </div>
        <div className="py-[18px] border-t border-[#EDEDED] border-0 border-solid flex flex-col space-y-[15px]">
          <div className="flex flex-col space-y-[30px]">
            <Marquee {...settings}>
              <div className="flex space-x-[30px]">
                {yahooTestimonials.map((items, index) => (
                  <RatingCard
                    key={index}
                    message={items.message}
                    name={items.name}
                    icon={"assets/yahoo.png"}
                    iconHeight={40}
                    iconWidth={278}
                  />
                ))}
              </div>
            </Marquee>
            <Marquee {...settings}>
              <div className="flex space-x-[30px]">
                {mercariTestimonials.map((items, index) => (
                  <RatingCard
                    key={index}
                    message={items.message}
                    name={items.name}
                    icon={"assets/mercari.png"}
                    iconHeight={58}
                    iconWidth={205}
                  />
                ))}
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTestimonials;
