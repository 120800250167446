import * as Sentry from "@sentry/node";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getRandomCarpets, RandomCarpetType } from "../../../services/carpets";
import { Button } from "../../ui/button";
import NewCarpetCard from "../NewCarpetCard";
import {
  findFullRated,
  getStatusArray,
} from "../../../../../../packages/shared/src/utils/string";
import Link from "next/link";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel";

type CarpetData = {
  description: string;
  height: string;
  id: string;
  main_image: string;
  right_image: string;
  left_image: string;
  center_image: string;
  status: number;
  title: string;
  width: string;
  evaluation_color: number;
  evaluation_density: number;
  evaluation_feel: number;
  evaluation_gloss: number;
  evaluation_softness: number;
  evaluation_texture: number;
  workshop: string;
  size: string;
  other_images: string;
};

const CarpetListHomePage: React.FC = () => {
  const [activeNav, setActiveNav] = useState<RandomCarpetType>("popular");

  const {
    data: carpetList,
    refetch,
    isLoading: loading,
    isError,
    error,
  } = useQuery<CarpetData[]>({
    queryKey: ["random-carpets", activeNav],
    queryFn: async () => getRandomCarpets(activeNav).then((res) => res.data),
    enabled: !!activeNav,
  });

  const handleChange = async (item: RandomCarpetType) => {
    setActiveNav(item);
    refetch();
  };

  useEffect(() => {
    if (isError) {
      Sentry.captureException(error);
    }
  }, [isError, error]);

  return (
    <div className="w-auto py-[30px] md:py-[100px] px-2 sm:px-0">
      <div className="max-w-[1400px] mx-auto w-full sm:w-auto flex flex-col space-y-[15px] md:space-y-[30px] p-2">
        <div>
          <h1 className="text-[#2f3030] text-[16px] md:text-[40px] leading-[60px] font-black text-center md:text-left mb-0 md:mb-4">
            高級 ペルシャ絨毯専門店 最高峰工房による手織りクム産シルク絨毯
          </h1>
          <div className="flex w-full space-y-[30px] md:space-y-0 flex-col md:flex-row justify-between items-center">
            <p className="text-[24px] md:text-[38px] leading-[45px] font-black mb-0">
              最高峰工房の作品集
            </p>
            <ul
              className={`flex space-x-0 sm:space-x-[30px] justify-start md:justify-end items-start md:items-center list-none flex-wrap cursor-pointer text-[16px] sm:text-[20px] leading-[24px] sm:leading-[30px] font-black mb-0 p-0 w-full md:w-auto`}
            >
              <li
                onClick={() => handleChange("popular")}
                className={`px-[10px] py-[5.5px] border-0 border-solid border-b-[3px] ${activeNav === "popular" ? "text-primary  border-b-primary" : "border-b-transparent"}`}
              >
                人気の作品
              </li>
              <li
                onClick={() => handleChange("recommended")}
                className={`px-[10px] py-[5.5px] border-0 border-solid border-b-[3px] ${activeNav === "recommended" ? "text-primary  border-b-primary" : "border-b-transparent"}`}
              >
                おすすめの作品
              </li>
              <li
                onClick={() => handleChange("new")}
                className={`px-[10px] py-[5.5px] border-0 border-solid border-b-[3px] ${activeNav === "new" ? "text-primary  border-b-primary" : "border-b-transparent"}`}
              >
                新着入荷
              </li>
            </ul>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
          </div>
        ) : (
          <>
            <div>
              {carpetList && carpetList.length > 0 && (
                <Carousel
                  opts={{
                    align: "end",
                    dragFree: true,
                    loop: true,
                  }}
                  className="sm:-mt-5 sm:-ml-3"
                >
                  <CarouselContent className="sm:py-5 sm:px-3">
                    {carpetList.map((carpet) => {
                      const imagesList = [
                        carpet.left_image,
                        carpet.center_image,
                        carpet.right_image,
                        ...(carpet?.other_images
                          ? JSON.parse(carpet.other_images)
                          : []),
                      ].filter(Boolean);
                      return (
                        <CarouselItem
                          className="md:basis-1/2 sm:pr-3"
                          key={`carpet_card__${carpet.id}`}
                        >
                          <NewCarpetCard
                            status={
                              carpet.status &&
                              getStatusArray(carpet.status).includes("new")
                                ? "New"
                                : carpet.status &&
                                    getStatusArray(carpet.status).includes(
                                      "sold"
                                    )
                                  ? "Sold"
                                  : ""
                            }
                            description={carpet.title}
                            image={carpet.main_image}
                            height={carpet.height}
                            width={carpet.width}
                            id={carpet.id}
                            fullRated={findFullRated(
                              carpet.evaluation_color,
                              carpet.evaluation_density,
                              carpet.evaluation_feel,
                              carpet.evaluation_gloss,
                              carpet.evaluation_softness,
                              carpet.evaluation_texture
                            )}
                            size={carpet.size}
                            workshop={carpet.workshop}
                            images={imagesList}
                          />
                        </CarouselItem>
                      );
                    })}
                  </CarouselContent>
                  <CarouselPrevious className="bg-black [&>*]:!size-5 left-2 2xl:-left-4" />
                  <CarouselNext className="bg-black [&>svg]:!size-5 right-2 2xl:-right-2" />
                </Carousel>
              )}
            </div>
            <div className="flex justify-center items-center">
              <Button
                asChild
                variant={"default"}
                className="border-none text-[20px] leading-[27px] rounded-none text-white opacity-1 hover:opacity-90 bg-primary !px-0 !py-0 cursor-pointer mt-[30px]"
              >
                <div className="w-full sm:w-auto text-center">
                  <Link href={`/carpets?view=${activeNav}`} passHref>
                    <a className="!text-white w-full px-0 sm:px-[49.5px] py-0 sm:py-[15.5px]">
                      全てのコレクションを見る
                    </a>
                  </Link>
                </div>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CarpetListHomePage;
