import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { auth } from "shared/src/firebase";

interface Props {
  counter?: number;
}

const CartMenu: React.FC<Props> = ({ counter }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  const redirect = () => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        router.push("/shopping-cart");
      } else {
        router.push("/sign-up");
      }
    });
  };

  return (
    <Link passHref href={"/shopping-cart"}>
      <a
        onClick={(e) => {
          e.preventDefault();
          redirect();
        }}
        className="!text-body hover:opacity-90 duration-200"
      >
        <div className="flex items-center space-x-2">
          <div className="size-5 md:size-6 relative">
            <Image
              src={"/assets/cart.svg"}
              height={24}
              width={24}
              alt="Cart"
              priority
              draggable={false}
            />
            {counter && counter > 0 ? (
              <span className="min-w-4 min-h-4 size-full flex items-center justify-center bg-primary text-white rounded-full text-xs absolute -top-3 -right-2 p-1 font-medium">
                {counter}
              </span>
            ) : null}
          </div>
          <span className="font-medium leading-[30px] tracking-[0.3px] hidden xs:block">
            {t("Cart")}
          </span>
        </div>
      </a>
    </Link>
  );
};

export { CartMenu };
