import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateYoutubeEmbedURL(youtubeURL: string) {
  try {
    const url = new URL(youtubeURL);
    let videoId = "";

    // If it's a shortened link like youtu.be/VIDEO_ID
    if (url.hostname === "youtu.be") {
      videoId = url.pathname.slice(1); // Remove leading "/"
    }
    // Otherwise, assume standard youtube.com/watch?v=VIDEO_ID
    else {
      videoId = url.searchParams.get("v") ?? "";
    }

    return videoId ? `https://www.youtube.com/embed/${videoId}` : "";
  } catch {
    // If the string is not a valid URL
    return "";
  }
}
