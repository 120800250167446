import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { CopyRight } from "../../molecules/CopyRight";
import { CustomerSupport } from "../../molecules/CustomerSupport";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-primaryDark px-4 2xl:px-[260px] pt-[50px] flex flex-col space-y-[30px] !font-dmSans">
      <div className="flex flex-wrap space-y-5 sm:space-y-0 sm:gap-5 items-start justify-between">
        <div className="w-full lg:w-fit">
          <Link href="/" passHref prefetch={false}>
            <a>
              <Image
                src="/assets/golestan-logo-new-white.svg"
                alt="Golestan"
                height={40}
                width={156}
                quality={100}
              />
            </a>
          </Link>
        </div>
        <section className="order-3 md:order-1">
          <CustomerSupport />
        </section>
        <section className="text-base md:!text-xl order-1 md:order-2">
          <div className="flex flex-col space-y-[15px]">
            <Link href="/about-us">
              <a target="_blank" className="!text-whiteText mt-1">
                {t("about golestan")}
              </a>
            </Link>
            <Link href="/customer-voice">
              <a target="_blank" className="!text-whiteText">
                {t("Customer Voice")}
              </a>
            </Link>
            <Link href="/contact">
              <a target="_blank" className="!text-whiteText">
                {t("Contact Us")}
              </a>
            </Link>
            <Link href="/request">
              <a target="_blank" className="!text-whiteText">
                {t("Request")}
              </a>
            </Link>
          </div>
        </section>
        <section className="text-base md:!text-xl order-2 md:order-3">
          <div className="flex flex-col space-y-[15px]">
            <Link href="/carpet-and-interior">
              <a target="_blank" className="!text-whiteText mt-1">
                {t("Carpet and Interior")}
              </a>
            </Link>
            <Link href="/articles">
              <a target="_blank" className="!text-whiteText">
                {t("ペルシャ絨毯の記事")}
              </a>
            </Link>
            <Link href="/guides">
              <a target="_blank" className="!text-whiteText">
                {t("Shopping Guide")}
              </a>
            </Link>
            <Link href="/articles/persian-carpet-faq">
              <a target="_blank" className="!text-whiteText">
                {t("faq")}
              </a>
            </Link>
            <Link href="/guides/payment-methods">
              <a target="_blank" className="!text-whiteText">
                {t("Payment method")}
              </a>
            </Link>
          </div>
        </section>
      </div>
      <div className="w-full h-px bg-white" />
      <div className="grid sm:flex flex-wrap items-center justify-start sm:justify-between pb-[30px] gap-5">
        <div className="order-last sm:order-first">
          <CopyRight />
        </div>
        <Link passHref href="/commercial-acts" target="_blank">
          <a className="!text-whiteText">特定商取引法に基づく表示</a>
        </Link>
        <Link target="_blank" passHref href="/payment-security">
          <a className="!text-whiteText">{t("セキュリティ対策について")}</a>
        </Link>
        <Link passHref target="_blank" href="/terms">
          <a className="!text-whiteText">{t("Terms of service")}</a>
        </Link>
        <Link passHref target="_blank" href="/privacy-policy">
          <a className="!text-whiteText">{t("Privacy policy")}</a>
        </Link>
      </div>
    </footer>
  );
};

export { Footer };
