import { ARTICLE_PAGE_SIZE } from "../lib/constant";
import { API } from "shared";

export const GetArticles = ({
  page = 1,
  pageSize = ARTICLE_PAGE_SIZE,
  tag,
}: {
  page?: number;
  pageSize?: number;
  tag?: string;
}) => {
  return API.get("/articles", {
    params: {
      page,
      pageSize,
      tag,
    },
  });
};

export const GetAllArticles = () => {
  return API.get("/articles/sitemap");
};

export const getFilteredArticles = () => {
  return API.get("/articles/filtered");
};

export const getLandingImages = () => {
  return API.get("/lp-images");
};

export const getSingleArticle = (id: string) => {
  return API.get(`/articles/${id}`);
};

export const getAllArticleTags = () => {
  return API.get("/articles/tags");
};
